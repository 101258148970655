import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRating } from 'vuetify/lib/components/VRating';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-1 pa-0",staticStyle:{"border-bottom":"1px solid #E6E5E5","width":"100%"}},[_c('div',{staticClass:"wrap d-flex flex-row align-center justify-space-between pt-4 pb-5 px-4",staticStyle:{"background":"#F0F3F4"}},[_c('div',{staticClass:"review_body"},[_c('div',{staticClass:"d-flex flex-wrap align-center justify-space-between member-info mb-5",class:{
          'member-info-grade-star-none': !_vm.displayStarGrade
        }},[_c('div',{staticClass:"d-flex align-center"},[(_vm.castReviewData.image_filename && _vm.castReviewData.site_member_id)?_c('AvatarImage',{staticClass:"member-avatar mr-2 mb-7",attrs:{"imageUrl":_vm.castReviewData.image_filename
              ? (_vm.AWS_S3_URL + "site_members/" + (_vm.castReviewData.site_member_id) + "/mypage/photo/" + (_vm.castReviewData.image_filename))
              : _vm.profileDefaultImage,"width":70,"height":70,"borderWidth":4}}):_c('AvatarImage',{staticClass:"member-avatar mr-2 mb-7",attrs:{"imageUrl":_vm.profileDefaultImage,"width":70,"height":70,"borderWidth":4}}),_vm._v(" "),_c('div',[_c('div',{staticClass:"d-flex align-center reviewer_name mb-2"},[_c('div',{staticClass:"mr-5",staticStyle:{"max-width":"340px"}},[_vm._v("\n                "+_vm._s(_vm.$t('mr'))+" "+_vm._s(_vm.name)+"\n              ")])]),_vm._v(" "),(_vm.isMember)?_c('div',{staticClass:"mb-2",staticStyle:{"font-weight":"bold","font-size":"10px","color":"#707070"}},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.castReviewData.site_member.review_count)+" "+_vm._s(_vm.$t('text_A7xv')))])]):_vm._e()])],1)]),_vm._v(" "),_c('div',[_c('div',{staticClass:"review-content-container"},[(_vm.castReviewData.rank <= 10)?_c('UserRankingHighlightBox',{staticStyle:{"border-radius":"15px 15px 0 0","padding-left":"25px !important"},attrs:{"rank":_vm.castReviewData.rank}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"satisfaction mb-4"},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.$t('text_E4qX')))]),_vm._v(" "),_c('span',{staticClass:"rating"},[_c(VRating,{staticClass:"px-0 mr-5",staticStyle:{"display":"inline-block"},attrs:{"background-color":"#B22C27","color":"#B22C27","length":"5","dense":"","size":"14","value":Number(_vm.castReviewData.satisfaction),"half-icon":"mdi-star-half-full","half-increments":"","readonly":""}})],1),_vm._v(" "),_c('span',{staticStyle:{"font-size":"20px","font-weight":"bold","color":"var(--main-color)"}},[_vm._v("\n                "+_vm._s(Number(_vm.castReviewData.overall_satisfaction).toFixed(1))+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"review_comment mb-7",staticStyle:{"white-space":"pre-line"},style:({
                'text-overflow': 'ellipsis',
                'border-radius': '0',
                'overflow': 'hidden',
                'display': '-webkit-box',
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': _vm.lineClamp,
              }),domProps:{"innerHTML":_vm._s(_vm.reviewOmittedText(_vm.unescapeHtml(_vm.comment), _vm.castReviewData.unfolding_flag))}}),_vm._v(" "),(!_vm.castReviewData.unfolding_flag)?_c('div',{staticClass:"text-center pb-3",staticStyle:{"color":"var(--main-color)","font-size":"var(--read-font-size-s)","font-weight":"bold"},on:{"click":function($event){_vm.castReviewData.unfolding_flag=true;_vm.lineClamp=''}}},[_vm._v("\n              "+_vm._s(_vm.$t('text_mS2L'))+"\n            ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-row align-center justify-space-between pa-4"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.castReviewData.date),expression:"castReviewData.date"}],staticClass:"date"},[_c('span',[_vm._v(_vm._s(_vm.$t('text_A7xv')))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("moment_date")(_vm.castReviewData.date)))])])]),_vm._v(" "),_c('div',{staticClass:"speech-bubble",class:{
              'speech-bubble-grade-star-none': !_vm.displayStarGrade,
              'bg-highlight': _vm.castReviewData.rank <= 10
            }})],1)])])]),_vm._v(" "),_c(VDialog,{staticStyle:{"z-index":"9999"},attrs:{"max-width":"400","transition":"dialog-bottom-transition"},model:{value:(_vm.guestAlert),callback:function ($$v) {_vm.guestAlert=$$v},expression:"guestAlert"}},[_c(VCard,{staticClass:"pa-3 text-center",staticStyle:{"font-weight":"bold"}},[_c('div',{staticClass:"pa-3 primary--text text-center"},[_vm._v("\n        ログイン/会員登録するとユーザープロフィールの閲覧が可能です\n      ")]),_vm._v(" "),_c('div',{staticClass:"px-3"},[_c(VBtn,{staticStyle:{"color":"var(--main-color)","font-weight":"bold"},attrs:{"block":"","small":"","elevation":"0"},on:{"click":function($event){_vm.guestAlert=false}}},[_vm._v("\n          閉じる\n        ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }