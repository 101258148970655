import Vue from 'vue';
import moment from 'moment';

Vue.filter('moment', function(date) {
  return moment(date).format('YY年M月DD日 H:mm');
});

Vue.filter('moment_date', function(date) {
  return moment(date).format('YYYY-MM-DD');
});

Vue.filter('reserve_moment', function(date) {
  const [y, m, d] = moment(date)
    .format('YYYY-MM-DD')
    .split('-');
  const _m = +m - 1;
  const _d = +d;
  //const day = '日月火水木金土'.charAt(new Date(y, _m, _d).getDay())
  //return `${moment(date).format('M/DD')}  (${day})   ${moment(date).format('H:mm')}`
  return `${moment(date).format('M/D')}  ${moment(date).format('H:mm')}`;
});

Vue.filter('youbi', function(date) {
  const [y, m, d] = date.split('-');
  const _m = +m - 1;
  const _d = +d;
  //const day = '日月火水木金土'.charAt(new Date(y, _m, _d).getDay());
  //return `${moment(date).format('MM/DD')} (${day})`;
  return `${moment(date).format('M/D')}`;
});

Vue.filter('time_only', function(date) {
  return `${moment(date).format('HH:mm')}`;
});
