<template>
  <div>
    <v-container class="pa-0">
        <BannerSlider 
          style="margin-top: -20px;"
          :class="{'mt-0': !isMobile}"
          :banners="recruitmentBanners" 
        />
      </v-container>
    <div class="py-2 pl-1 pr-5" style="background: #fff">
      <h2
        class="content-box-header primary--text d-flex align-center justify-space-between"
      >
        <div class="d-flex align-center">
          <GoodMain class="mr-1" />
          <span v-if="isSoon">
            {{ $t('text_n5c6') }}
            {{
              this.$store.state.siteMemberData.nickname || `${$t('guest')}`
            }}</span
          >
          <span v-else>
            {{ $t('text_sd54') }}
            {{
              this.$store.state.siteMemberData.nickname || `${$t('guest')}`
            }}</span
          >
        </div>
        <div class="dropdown_menu">
          <div class="dropdown-button">
            <button @click="toggleMenu('click')">
              <iconEarth class="dropdown-button-icon"></iconEarth>
              <p class="dropdown-button-index">language</p>
              <p class="dropdown-button-click-icon">{{ clickIcon }}</p>
            </button>
          </div>
        </div>
        <!-- <div @click="openDialog = !openDialog">
          <IconQuestion style="height: auto" />
        </div> -->
      </h2>
    </div>
    <div v-if="openFlag" class="pl-5 pr-5 dropdown-items content-box-header justify-space-between">
      <div class="row no-gutters" style="height: 98px;">
        <div style="height: 34px; margin-top: 10px; margin-bottom: 5px;" class="col-6">
          <div class="dropdown-items-button" :style="addStyleColor(dropDownButtonsStatus.en.selectFlag,'button')" type="button" @click="toggleMenu('en')">
            <p class="dropdown-items-button-play-icon">
              <svg id="horizontal-arrow" xmlns="http://www.w3.org/2000/svg" width="5" height="6" viewBox="0 0 5 6">
                <path id="多角形_67" data-name="多角形 67" d="M3,0,6,5H0Z" transform="translate(5) rotate(90)" :style="addStyleColor(dropDownButtonsStatus.en.selectFlag,'icon')"/>
              </svg>
            </p>
            <p class="dropdown-items-button-index">English</p>
          </div>
        </div>
        <div style="height: 34px; margin-top: 10px; margin-bottom: 5px;" class="col-6">
          <div style="float:right;" class="dropdown-items-button" :style="addStyleColor(dropDownButtonsStatus.zh_cn.selectFlag,'button')" type="button" @click="toggleMenu('zh-cn')">
            <p class="dropdown-items-button-play-icon">
              <svg id="horizontal-arrow" xmlns="http://www.w3.org/2000/svg" width="5" height="6" viewBox="0 0 5 6">
                <path id="多角形_67" data-name="多角形 67" d="M3,0,6,5H0Z" transform="translate(5) rotate(90)" :style="addStyleColor(dropDownButtonsStatus.zh_cn.selectFlag,'icon')"/>
              </svg>
            </p>
            <p class="dropdown-items-button-index">簡体中文</p>
          </div>
        </div>
        <div style="height: 34px; margin-bottom: 10px; margin-top: 5px ;" class="col-6">
          <div class="dropdown-items-button" :style="addStyleColor(dropDownButtonsStatus.zh_tw.selectFlag,'button')" type="button" @click="toggleMenu('zh-tw')">
            <p class="dropdown-items-button-play-icon">
              <svg id="horizontal-arrow" xmlns="http://www.w3.org/2000/svg" width="5" height="6" viewBox="0 0 5 6">
                <path id="多角形_67" data-name="多角形 67" d="M3,0,6,5H0Z" transform="translate(5) rotate(90)" :style="addStyleColor(dropDownButtonsStatus.zh_tw.selectFlag,'icon')"/>
              </svg>
            </p>
            <p class="dropdown-items-button-index">繁體中文</p>
          </div>
        </div>
        <div style="height: 34px; margin-bottom: 10px; margin-top: 5px;" class="col-6">
           <div style="float:right;" class="dropdown-items-button" :style="addStyleColor(dropDownButtonsStatus.ko.selectFlag,'button')" type="button" @click="toggleMenu('ko')">
            <p class="dropdown-items-button-play-icon">
              <svg id="horizontal-arrow" xmlns="http://www.w3.org/2000/svg" width="5" height="6" viewBox="0 0 5 6">
                <path id="多角形_67" data-name="多角形 67" d="M3,0,6,5H0Z" transform="translate(5) rotate(90)" :style="addStyleColor(dropDownButtonsStatus.ko.selectFlag,'icon')"/>
              </svg>
            </p>
            <p class="dropdown-items-button-index">한국어</p>
           </div>
         </div>
      </div>
    </div>
    <v-carousel
      :cycle="true"
      interval="5000"
      height="50vh"
      show-arrows-on-hover
      hide-delimiter-background
      delimiter-icon="mdi-circle"
      style="font-size: 10px; max-height: 400px"
    >
      <div v-if="recommendCastLoading" style="height: 50vh">
        <loading-image />
      </div>
      <v-carousel-item
        v-for="(cast, i) in this.$store.state.bindCast.recommendCasts"
        :key="i"
      >
        <div v-on:click="toCastProfileDialog(cast)" style="cursor: pointer">
          <v-img
            :src="
              defaultImage(
                cast.thumbnail_url,
                cast.shop_default_thumbnail_set_name
              )
            "
            height="50vh"
            contain
            style="max-height: 400px; position: relative"
          >
            <!-- ランキングタグ -->
            <!-- <RankingTags
              v-if="cast.ranking_tags.length"
              style="position:absolute;left:0px;"
              :style="getRankingTagsStyleObj(cast)"
              :rankingTags="cast.ranking_tags"
            /> -->
            <v-row
              align="end"
              id="prof-photo__item"
              class="ma-0 pa-2 cast-list__text"
            >
              <v-col class="pa-1">
                <!-- <div
                  v-show="isNewcommer(cast)"
                  class="mt-1"
                  style="width: 100%"
                >
                  <span
                    style="
                      padding: 0 3px;
                      color: #000;
                      background-color: #fff;
                      border-radius: 3px;
                      font-size: var(--read-font-size-xs);
                    "
                    >{{ $t('new') }}</span
                  >&nbsp;&nbsp;&nbsp;
                  <span>{{ debutDate(cast.debut_date) }}</span>
                </div>
                <div
                  class="d-flex aling-center"
                  style="
                    width: 100%;
                    line-height: var(--read-font-size-m);
                    letter-spacing: 0.5px;
                  "
                  v-if="cast.display_cast_schedules_count <= 10"
                >
                  <IconBeginner class="mr-1" style="fill: #fff; width: 12px" />
                  <span style="padding-top: 5px"
                    >{{ $t('attendance_count')
                    }}{{ cast.display_cast_schedules_count }}</span
                  >
                </div> -->
                <div>
                  {{ cast.site_cast_name }}&nbsp;&nbsp;&nbsp;{{ $t('age') }}
                  {{ cast.age }}<br />
                  T{{ cast.height }}&nbsp;&nbsp;&nbsp;{{ cast.bust }}({{
                    cupList[cast.cup]
                  }})-{{ cast.waist }}-{{ cast.hip }}
                </div>
                <!-- <div style="margin: 2px 0 2px 0">
                  <span
                    style="
                      padding: 1px 6px;
                      color: #fff;
                      font-size: var(--read-font-size-xs);
                      border-radius: 3px;
                    "
                    :style="{
                      backgroundColor:
                        cast.business_type_id === 1
                          ? '#54d1f0'
                          : cast.business_type_id === 2
                          ? '#E4C76A'
                          : cast.business_type_id === 3
                          ? '#ff96b7'
                          : '#ccc',
                    }"
                  >
                    {{ $t(`business_types.${cast.business_type_id}.name`) }}
                  </span>
                </div> -->
                <div
                  style="font-size: var(--read-font-size-xs); line-height: 16px"
                >
                  <!-- {{ cast.shop_name }}<br /> -->
                  {{ cast.min_course_minute }}{{ $t('min') }}&nbsp;&nbsp;&yen;{{
                    cast.min_course_money.toLocaleString()
                  }}～
                </div>
                <!-- <v-progress-circular
                  color="#B22C28"
                  :rotate="-90"
                  :size="54"
                  :width="3"
                  :value="scoreRate(cast.match_score)"
                  class="overline mb-1 pa-0"
                  style="position: absolute; right: 8px; bottom: 8px"
                >
                  <p class="text-center ma-0 pa-0 font-weight-bold">
                    <span
                      style="
                        font-size: 0.625rem;
                        letter-spacing: 0;
                        line-height: 0em;
                      "
                      >マッチ度</span
                    ><br />
                    <span
                      style="
                        font-size: var(--read-font-size-s);
                        line-height: 0.5em;
                        padding-left: 2px;
                      "
                      >{{ scoreRate(cast.match_score) }}%</span
                    >
                  </p>
                </v-progress-circular> -->
                <CastLikeButton2
                  style="position: absolute; right: 14px; bottom: 14px"
                  :castId="cast.cast_id"
                  :isFavorite="
                    $store.state.siteMemberData.followCastIds.includes(
                      cast.cast_id
                    )
                      ? true
                      : false
                  "
                  :siteMemberFollowCount="
                    showFollowerCountData(
                      $store.state.followerCountData.recommendCasts,
                      cast.cast_id
                    )
                  "
                />
              </v-col>
            </v-row>
            <v-img
              :src="
                defaultImage(
                  cast.thumbnail_url,
                  cast.shop_default_thumbnail_set_name
                )
              "
              height="50vh"
              style="z-index: -999; filter: blur(5px); max-height: 400px"
            />
          </v-img>
        </div>
      </v-carousel-item>
    </v-carousel>
    <v-dialog
      v-model="openDialog"
      max-width="400"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card class="pa-3 text-center" style="font-weight: bold">
        <p class="pa-3 ma-0 primary--text text-left">{{ $t('text_tby8') }}</p>
        <div class="pa-3 text-left">
          {{ $t('text_5e49') }}<br /><br />
          {{ $t('text_x2zu') }}
        </div>
        <p class="pa-3 ma-0 primary--text text-left">{{ $t('text_qsn4') }}</p>
        <div class="pa-3 text-left">
          {{ this.nickname }}{{ $t('text_qx59') }}<br /><br />
          {{ $t('text_9qp6') }}
        </div>
        <p class="pa-3 ma-0 primary--text text-left">
          {{ $t('text_hzsa3') }}
        </p>
        <div class="pa-3 text-left">
          {{ $t('text_p5hg') }}
        </div>
        <p class="pa-3 ma-0 primary--text text-left">
          {{ $t('text_w3km') }}
        </p>
        <div class="pa-3 text-left">
          {{ $t('text_h7wy') }}
        </div>
        <div class="px-3">
          <v-btn
            block
            small
            elevation="0"
            v-on:click="openDialog = !openDialog"
            style="color: var(--main-color); font-weight: bold"
            >{{ $t('close') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import RankingTags from '~/components/pages/common/RankingTags.vue';
import BtnHowLink from '~/assets/img/bn-link.png';
import BannerSlider from '~/components/pages/home/BannerSlider.vue';
import CastLikeButton2 from '~/components/layouts/CastLikeButton2.vue';
import GoodMain from '@/assets/img/good-main.svg';
import IconBeginner from '~/assets/img/icon-beginner.svg';
import iconEarth from '~/assets/img/icon-earth.svg';
import _ from 'lodash';

const moment = require('moment');

export default {
  components: {
    // RankingTags,,
    CastLikeButton2,
    BannerSlider,
    GoodMain,
    IconBeginner,
    iconEarth,
  },
  computed: {
    cupList: function() {
      return this.$store.state.call.cupList;
    },
    nickname: function() {
      if (this.$store.state.auth.loggedIn) {
        return this.$store.state.siteMemberData.nickname;
      } else {
        return this.$t('guest');
      }
    },
  },
  mounted: async function() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  data() {
    return {
      isGuest: !this.$store.state.auth.loggedIn,
      isSoon: true,
      dropDownButtonsStatus: {
        en:    { value: 'en'   , selectFlag: false },
        zh_cn: { value: 'zh-cn', selectFlag: false },
        zh_tw: { value: 'zh-tw', selectFlag: false },
        ko:    { value: 'ko'   , selectFlag: false },
      },
      recruitmentBanners: [
        {'img' : BtnHowLink, 'url' : '/how' },
      ],
      openFlag: false,
      recommendCastLoading: false,
      openDialog: false,
      clickIcon: '▼',
      isMobile: false,
    };
  },
  methods: {
    getSearchCastTop: function() {
      this.recommendCastLoading = true;
      const after15minute = moment()
        .add(15, 'minutes')
        .format('YYYY-MM-DD HH:mm:00');
      let homeBusinessTrip = 0;
      if (this.$store.state.auth.loggedIn === true) {
        homeBusinessTrip =
          this.$store.state.siteMemberData.last_use_place_search_type === 1
            ? 1
            : 0;
      }
      const subAreaValue =
        homeBusinessTrip === 1
          ? this.$store.state.siteMemberData.home_sub_area_id
          : null;
      const params = {
        areaValue: this.$store.state.call.areaValue || 6,
        subAreaValue: subAreaValue,
        arrivalDateTimeValue: after15minute,
        email: `${this.$store.state.auth.loggedIn}`
          ? this.$store.state.auth.user
            ? this.$store.state.auth.user.email
            : ''
          : '',
        castIds: [],
        favaliteTagIds: [],
        homeBusinessTrip: homeBusinessTrip,
        genreValue: [],
        hotelId: [],
        ngSmokeValue: `${this.$store.state.auth.loggedIn}`
          ? this.$store.state.call.ngSmokeValue
          : [],
        ngTattooValue: `${this.$store.state.auth.loggedIn}`
          ? this.$store.state.call.ngTattooValue
          : [],
        ngBodyPiercingFlag: `${this.$store.state.auth.loggedIn}`
          ? this.$store.state.call.ngBodyPiercingFlag
          : [],
        search_under_hair_type: `${this.$store.state.auth.loggedIn}`
          ? this.$store.state.call.searchUnderHairType
          : null,
        search_drink_type: `${this.$store.state.auth.loggedIn}`
          ? this.$store.state.call.searchDrinkType
          : null,
        selectedNoDateValue: false,
        rangeStartTime1: '',
        rangeStartTime2: '',
        siteMemberTel: `${this.$store.state.auth.loggedIn}`
          ? this.$store.state.siteMemberData.tel
          : '',
        limit: 8,
        sitemember_important_cast_feature: this.$store.state.siteMemberData
          .recommend_site_member_important_cast_features,
        loggedIn: `${this.$store.state.auth.loggedIn}`,
      };
      this.$axios
        .post('public/search-casts-today-or-week', params, {})
        .then((response) => {
          let casts = _.map(response.data.castData, (v) => {
            // ログインしている場合は、会員の設定で割り振る
            // ログインしていない場合は、[4,4,3,2,2]で割り振る
            const vectorU =
              this.$store.$auth.loggedIn === true
                ? _.flatMap(
                    this.$store.state.siteMemberData
                      .recommend_site_member_important_cast_features,
                    (v) => v.point
                  )
                : [4, 4, 3, 2, 2];
            const vectorC = [
              v.evaluation1_avg,
              v.evaluation2_avg,
              v.evaluation3_avg,
              v.evaluation4_avg,
              v.evaluation5_avg,
            ];
            const score = this.matchScore(vectorU, vectorC);
            v['match_score'] = score;
            return v;
          });
          // Sort
          casts = _.orderBy(
            casts,
            ['match_score', 'overall_satisfaction_avg', 'debut_date'],
            ['desc', 'desc', 'desc']
          );
          // 女性情報をStoreに保存
          this.$store.dispatch('bindCast/saveRecommendCastsData', casts);
          this.isSoon = response.data.isSoon;
          // フォロワーカウント整形
          const followerCount = this.pickFollowerCountData(
            response.data.castData
          );
          // フォロワーカウント
          this.$store.dispatch(
            'followerCountData/saveRecommendCastsFollowerCountData',
            this.$store.state.followerCountData.recommendCasts.concat(
              followerCount
            )
          );
        })
        .finally(() => {
          this.recommendCastLoading = false;
        });
    },
    toggleMenu: function(action) {
      if (action === 'click') {
        this.openFlag = !this.openFlag;
        if (this.openFlag) {
          this.clickIcon = '▲'
        } else {
          this.clickIcon = '▼'
        }
        Object.keys(this.dropDownButtonsStatus).forEach((item) => {
          if (this.$i18n.locale === this.dropDownButtonsStatus[item].value) {
            this.dropDownButtonsStatus[item].selectFlag = true;
          }
        })
      } else {
        this.$store.dispatch('bindCast/deleteCastsData');
        this.$store.dispatch('bindCast/saveCastData', []);
        window.location.href = process.env.APP_URL+'/'+action
      }
      return
    },
    addStyleColor: function(flag,type) {
      let value;
      if (type === 'button') {
        value = {
          backgroundColor: flag ? '#B22C27' : 'white',
          border: flag ? '' :'1px solid #8D8D8D',
          color: flag ? 'white' : '#1A1A1A',
        }
      } else if (type === 'icon') {
        value = {
          fill: flag ? 'white' : '#8D8D8D',
        }
      }
      return value;
    },
    scoreRate: function(score) {
      const result =
        this.$store.state.auth.loggedIn === true ? Math.floor(score) : '--';
      return result;
    },
    isNewcommer: function(cast) {
      const before90days = moment()
        .subtract(90, 'days')
        .format('YYYY-MM-DD');
      return cast.debut_date > before90days;
    },
    getRankingTagsStyleObj(cast) {
      const position = this.isNewcommer(cast) ? 150 : 128;
      return {
        bottom: `${String(position)}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box-header {
  font-size: 13px;
  line-height: var(--read-font-size-m);
  border-left: none;
}
.dropdown_menu {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1A1A1A;
}
.dropdown-button {
  width: 80px;
  height: 24px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-button-index {
  font-family: 'Noto Sans JP',Medium;
  margin-top: 4px;
  margin-right: 2px;
  margin-bottom: 6px;
  width: 47px;
  height: 15px;
  font-size: 10px;
}
.dropdown-button-click-icon {
  margin-top: 10px;
  margin-bottom: 9px;
  margin-right: 4px;
  margin-left: 4.5px;
  font-size: 6px;
}
.dropdown-button-icon {
  margin-left:4.02px;
  margin-right:4.5px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 14px;
  height: 14px;
}
.dropdown-button button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-items {
  width: 100%;
  height: 98px;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color:rgba(255,255,255, 0.9);
  display: flex;
  z-index: 2;
}
.dropdown-items-button {
  opacity: 1;
  width: 97%;
  height: 34px;
  display: inline-block;
  position: relative;
}
.dropdown-items-button p {
  margin-top: 8px;
  margin-bottom: 10px;
}
.dropdown-items-button-play-icon {
  display: inline-block;
  position: absolute;
  font-size: 6px;
  margin-left: 6%;
  z-index: 2;
}
.dropdown-items-button-index {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-family: 'Noto Sans JP',Medium;
  z-index: 1;
}
.v-progress-circular {
  background: linear-gradient(90deg, #fff0f8ef, #faf2ed) !important;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 10;
}
</style>
