<template>
  <div class="mr-1 pa-0" style="border-bottom:1px solid #E6E5E5;width:100%;">
    <div class="wrap d-flex flex-row align-center justify-space-between pt-4 pb-5 px-4" style="background:#F0F3F4;">
      <div class="review_body">
        <div
          class="d-flex flex-wrap align-center justify-space-between member-info mb-5"
          :class="{
            'member-info-grade-star-none': !displayStarGrade
          }"
        >
          <div class="d-flex align-center">
            <AvatarImage v-if="castReviewData.image_filename && castReviewData.site_member_id"
              class="member-avatar mr-2 mb-7"
              :imageUrl="castReviewData.image_filename
                ? `${AWS_S3_URL}site_members/${castReviewData.site_member_id}/mypage/photo/${castReviewData.image_filename}`
                : profileDefaultImage"
              :width="70"
              :height="70"
              :borderWidth="4"
            />
            <AvatarImage v-else
              class="member-avatar mr-2 mb-7"
              :imageUrl="profileDefaultImage"
              :width="70"
              :height="70"
              :borderWidth="4"
            />
            <div>
              <div class="d-flex align-center reviewer_name mb-2">
                <div class="mr-5" style="max-width: 340px;">
                  {{ $t('mr') }} {{ name }}
                </div>
              </div>
              <div v-if="isMember" class="mb-2" style="font-weight:bold;font-size:10px;color:#707070;">
                <span class="mr-3">{{ castReviewData.site_member.review_count }} {{ $t('text_A7xv') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="review-content-container">
            <UserRankingHighlightBox
              v-if="castReviewData.rank <= 10"
              :rank="castReviewData.rank"
              style="border-radius: 15px 15px 0 0;padding-left: 25px !important;"
            />
            <div class="pa-4">
              <div class="satisfaction mb-4">
                <span class="mr-3">{{ $t('text_E4qX') }}</span>
                <span class="rating">
                  <v-rating
                    background-color="#B22C27"
                    color="#B22C27"
                    class="px-0 mr-5"
                    length="5"
                    dense
                    size="14"
                    :value="Number(castReviewData.satisfaction)"
                    half-icon="mdi-star-half-full"
                    half-increments
                    readonly
                    style="display:inline-block;"
                  ></v-rating>
                </span>
                <span style="font-size:20px;font-weight:bold;color: var(--main-color);">
                  {{ Number(castReviewData.overall_satisfaction).toFixed(1) }}
                </span>
              </div>
              <!-- APIからのテキストはpre-lineで改行、HTMLタグが混じってることがあるのでv-htmlで表示　-->
              <div
                class="review_comment mb-7"
                style="white-space: pre-line;"
                :style="{
                  'text-overflow': 'ellipsis',
                  'border-radius': '0',
                  'overflow': 'hidden',
                  'display': '-webkit-box',
                  '-webkit-box-orient': 'vertical',
                  '-webkit-line-clamp': lineClamp,
                }"
                v-html="reviewOmittedText(unescapeHtml(comment), castReviewData.unfolding_flag)"
              ></div>
              <!-- 続きを読む -->
              <div
                v-if="!castReviewData.unfolding_flag"
                v-on:click="castReviewData.unfolding_flag=true;lineClamp=''"
                class="text-center pb-3"
                style="color:var(--main-color);font-size:var(--read-font-size-s);font-weight:bold;"
              >
                {{ $t('text_mS2L') }}
              </div>
            </div>
            <div class="d-flex flex-row align-center justify-space-between pa-4">
              <div class="date" v-show="castReviewData.date">
                <span>{{ $t('text_A7xv') }}</span>
                <span>{{ castReviewData.date | moment_date }}</span>
              </div>
              <!-- <HelpfullButton
                :count="castReviewData.help_full_count"
                :default="castReviewData.is_help_full"
                :cast-review-id="castReviewData.id"
                :site-member-id="castReviewData.site_member_id"
                :date="castReviewData.date"
                :disabled="!comment"
              /> -->
            </div>
            <!-- 吹き出し -->
            <div
              class="speech-bubble"
              :class="{
                'speech-bubble-grade-star-none': !displayStarGrade,
                'bg-highlight': castReviewData.rank <= 10
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="guestAlert" max-width="400" transition="dialog-bottom-transition" style="z-index: 9999">
      <v-card class="pa-3 text-center" style="font-weight: bold">
        <div class="pa-3 primary--text text-center">
          ログイン/会員登録するとユーザープロフィールの閲覧が可能です
        </div>
        <div class="px-3">
          <v-btn block small elevation="0" v-on:click="guestAlert=false" style="color: var(--main-color); font-weight: bold">
            閉じる
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import HelpfullButton from "~/components/pages/cast/HelpfullButton.vue";
import FollowBtn from '~/components/pages/user/FollowBtn.vue';
import profileDefaultImage from "~/assets/img/profile.jpg"; // デフォルト画像
import AvatarImage from '~/components/pages/mypage/AvatarImage.vue';
import UserRankingHighlightBox from "~/components/pages/common/UserRankingHighlightBox.vue";
import IconGrade from "~/components/pages/common/IconGrade.vue";

export default {
  name: "Review",
  props: ["castReviewData", "castUrlHash"],
  components: {
    // HelpfullButton,
    FollowBtn,
    AvatarImage,
    UserRankingHighlightBox,
    IconGrade
  },
  data() {
    return {
      isGuest: !this.$store.state.auth.loggedIn,
      guestAlert: false,
      profileDefaultImage: profileDefaultImage,
      AWS_S3_URL: process.env.AWS_S3_URL,
      lineClamp: 10,
    };
  },
  methods: {
    toMemberPage() {
      if (!this.isMember) return;
      this.toCastProfileTransition(this.castUrlHash, this.castReviewData, 'siteMember');
    },
    unescapeHtml: function (target) {
      if (typeof target !== "string") return target;
      if (!target) return '-クチコミなし-';
      let patterns = {
        "&lt;": "<",
        "&gt;": ">",
        "&amp;": "&",
        "&quot;": '"',
        "&#x27;": "'",
        "&#x60;": "`",
      };
      return target.replace(/&(lt|gt|amp|quot|#x27|#x60);/g, function (match) {
        return patterns[match];
      });
    },
  },
  computed: {
    isMember() {
      return this.castReviewData.site_member ? true : false;
    },
    name() {
      let name = this.castReviewData.name;
      return name;
    },
    profileImg() {
      if (this.isMember) {
        // DBに会員プロフ画像がなければアセットからデフォルト画像を返す
        if (
          this.castReviewData.site_member.site_member_photos &&
          this.castReviewData.site_member.site_member_photos.length != 0 &&
          this.castReviewData.site_member.site_member_photos[0].image_filename != null
        ) {
          return `${process.env.AWS_S3_URL}customers/${this.castReviewData.site_member.id}/profile/${this.castReviewData.site_member.site_member_photos[0].image_filename}`;
        }
      }

      return this.default_image;
    },
    comment() {
      let comment_element = document.createElement("div");
      comment_element.innerHTML = this.castReviewData.comment;

      // cast_review.commentにHTMLタグが含まれていて、
      // かつフォントサイズが数値ベタ書きの場合、Tokyo Escort OTOMEの標準フォントサイズに
      // 馴染まなくなるためフォントサイズ指定を削除
      comment_element.querySelectorAll("*").forEach(function (elem) {
        let font = elem.style.fontSize;
        if (font && font.match(/^(\d+)/)) {
          elem.style.fontSize = "";
        }
      });
      return comment_element.innerHTML;
    },
    displayStarGrade() {
      return this.castReviewData.site_member
          && this.castReviewData.site_member.star_public_flag
          && this.castReviewData.site_member.grade_public_flag
    }
  },
};
</script>

<style scoped>
.main {
  font-size: var(--read-font-size-s);
}
.reviewer_name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 1rem;
  color: var(--read-font-color-primary);
}
::v-deep .follow__btn {
  width: auto !important;
  height: 1.2rem !important;
  font-size: 0.625rem !important;
}
.nonmember {
  font-size: var(--read-font-size-s);
}
.member-info {
  height: 90px;
}
.member-info-grade-star-none {
  height: 83px;
}
.review-content-container {
  background: #fff;
  border-radius: 15px;
  position: relative;
}
.speech-bubble {
  position: absolute;
  top: -45px;
  left: 58px;
  margin-left: -34px;
  border: 11px solid transparent;
  border-bottom: 40px solid #fff;
}
.speech-bubble-grade-star-none {
  top: -39px;
}
.bg-highlight {
  border-bottom: 40px solid #FFF9E5;
}
.satisfaction {
  font-size: 1rem;
  font-weight: bold;
  color: var(--main-color);
}
.satisfaction .rating {
  color: var(--main-color);
}
.review_comment {
  font-size: 14px;
  white-space: pre-line;
  color: #4B4B4B;
  line-height: 1.5rem;
}
.review_body {
  width: 100%;
}
.date {
  font-weight: bold;
  font-size: 12px;
  color: var(--main-color);
}
.satisfaction >>> .v-icon {
  padding: 0 0 2px 0 !important;
}
.grade-star {
  border-radius: 3px;
  background: #fff;
  font-size: 0.65rem;
  font-weight: bold;
  color: #333333;
  height: 26px;
  box-sizing: border-box;
}
</style>
