<template>
  <div class="v-carousel-wrap">
    <v-carousel
      height="110px"
      interval="5000"
      :cycle="true"
      :show-arrows="false"
      :hide-delimiter-background="true"
      :hide-delimiters="banners.length == 1 ? true : false"
      :style="banners.length > 1 ?'padding: 0 0 23px;' : 'padding: 0 0 10px;'"
    >
      <v-carousel-item
        v-for="(banner, i) in banners"
        :key="i"
        :src="banner.img"
        contain
        class="carousel-item"
        @click="pushTo(banner.url)"
      >
      </v-carousel-item>
    </v-carousel>
  </div>
</template>
<script>

export default {
  props: {
    banners: {
      type: Array
    }
  },
  methods: {
    adjustCarouselHeight() {
      this.$nextTick(() => {
        const image = this.$el.querySelector('.custom-carousel img');
        if (image) {
          const carousel = this.$el.querySelector('.custom-carousel');
          carousel.style.height = `${image.offsetHeight}px`;
        }
      });
    },
    pushTo(url) {
      // Nuxt.js の router.push を使用
      if (url == '/how') {
        window.open("https://lp.tokyoescort-otome.com/how/", "_blank");
      } else {
        this.$router.push(url);
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.adjustCarouselHeight);
    this.adjustCarouselHeight();
    this.$nextTick(() => {
      const controlsItems = this.$el.querySelectorAll('.v-carousel__controls__item');
      controlsItems.forEach(item => {
        item.style.color = '#999999';
      })
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustCarouselHeight);
  }
};
</script>
<style lang="scss" scoped>
.v-carousel-wrap {
  background-color: rgba(162, 182, 199, 0.05);
  padding: 10px 5px 0;
  .carousel-item {
    cursor: pointer;
    width: 100%;
  }
  .v-carousel__controls{
    position: static;
  }
}
</style>